.alert {
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin-bottom: 16px;
  align-items: center;

  &-warning {
    background-color: #f3c834;
    color: #fff;
  }

  &-info {
    background-color: #d1ecf1;
    color: #0c5460;
  }

  mat-icon {
    padding: 4px 8px 4px 4px;
  }
}
