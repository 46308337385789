/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.legacy-core();
@include mat.core();

@import '@angular/material/theming';
@import 'styles/variables';
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/grid';

$ectr-custom-primary: (
  900: #004280,
  800: #005591,
  700: #005f9a,
  600: #006aa4,
  500: $ectr-custom-primary500,
  400: #2687b8,
  300: #4d9cc4,
  200: #80b9d5,
  100: #b3d5e6,
  50: #e0eef5,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$custom-typography: mat.define-typography-config(
  $font-family: 'Lato, sans-serif',
  $headline-1: mat.define-typography-level(102px, px-to-rem(110), normal),
  $headline-2: mat.define-typography-level(64px, px-to-rem(77), 300),
  $headline-3: mat.define-typography-level(50px, px-to-rem(58), normal),
  $headline-4: mat.define-typography-level(35px, px-to-rem(43), normal),
  $headline-5: mat.define-typography-level(25px, px-to-rem(33), bold),
  $headline-6: mat.define-typography-level(21px, px-to-rem(33), bold),
  $subtitle-1: mat.define-typography-level(17px, px-to-rem(22), bold),
  $subtitle-2: mat.define-typography-level(15px, px-to-rem(20), bold),
  $body-1: mat.define-typography-level(17px, px-to-rem(24), normal),
  $body-2: mat.define-typography-level(15px, px-to-rem(20), normal),
  $caption: mat.define-typography-level(13px, px-to-rem(20), 400),
  $button: mat.define-typography-level(17px, px-to-rem(16), 900),
);

$ectr-primary: mat.define-palette($ectr-custom-primary);
$ectr-accent: mat.define-palette($ectr-custom-primary);
$ectr-warn: mat.define-palette($mat-red);

$ectr-theme: mat.define-light-theme(
  (
    color: (
      primary: $ectr-primary,
      accent: $ectr-accent,
      warn: $ectr-warn,
    ),
    typography: $custom-typography,
  )
);

@include mat.all-legacy-component-themes($ectr-theme);
@include mat.all-component-themes($ectr-theme);

@import './styles/material/material';
@import './styles/fixes';
@import './styles/alerts';
@import './styles/datagrid';
@import './styles/pxd-table';
@import './styles/table';

:root {
  --primary-color: #0072ab;
  --primary-color-rgb-value: 0, 114, 171;
  --secondary-color: #fff;
  --highlight-color: #00a3e0;
  --table-dashbord-color: rgba(0 0 0 / 87%);
  --table-heading-color: rgba(0 0 0 / 87%);
  --sidenav-color: #fff;
  --table-border-color: #e9e9e9;
  --button-border: rgba(0 0 0 / 12%);
  --columns: 1;
  --cell-size: 76px;
  --cell-padding: 16px;
  --error-color: #d3292e;
}

// adding fonts for this application
@font-face {
  font-family: Lato;
  src: url(assets/fonts/Lato/Lato-Black.woff2);
  font-weight: 900;
}

@font-face {
  font-family: Lato;
  src: url(assets/fonts/Lato/Lato-Bold.woff2);
  font-weight: 700;
}

@font-face {
  font-family: Lato;
  src: url(assets/fonts/Lato/Lato-Regular.woff2);
  font-weight: normal;
}

@font-face {
  font-family: Lato;
  src: url(assets/fonts/Lato/Lato-Light.woff2);
  font-weight: 300;
}

.row > * {
  box-sizing: border-box;
}

/////////////////// GENERAL //////////

html,
body {
  margin: 0;

  /* font-weight: normal; */
  // overflow-x: hidden;
  font-family: Lato;
  font-size: 15px;
  letter-spacing: 0.51px;
  min-height: 100vh;
  --mdc-typography-body1-font-size: 15px;
  --mat-standard-button-toggle-selected-state-background-color: var(
    --primary-color
  );
  --mat-standard-button-toggle-selected-state-text-color: white;
}

.mat-mdc-unelevated-button {
  white-space: nowrap;
}
.mat-mdc-outlined-button {
  white-space: nowrap;
}

hr {
  background: #ebebeb;
  height: 1px;
  border: none;
  margin: 28px 0;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

h2 {
  font-size: 21px;
  font-weight: 400;
  color: #222;
}

h3 {
  font-size: 17px;
  font-weight: bold;
  color: #222;
  padding-top: 32px;
}

.hint {
  font-size: 11px;
  font-style: italic;
  color: rgba(0 0 0 / 40%);
}

.error-snackbar {
  background: #0000000a;
  color: $error;
}

nav {
  height: 100%;
  width: 100%;
  overflow: auto;
}

// TABLES

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 15px;
  background-color: #fff;
}

th,
td {
  text-align: left;
  padding: var(--cell-padding);

  &.number {
    text-align: right;
  }
}

th {
  line-height: 1.1;
  color: var(--table-heading-color);

  &.number {
    text-align: right;
  }

  .arrow-up {
    margin-bottom: -4px;
  }
}

// FORMS

.mat-form-field-subscript-wrapper {
  margin-top: 0;
  padding: 8px 11px 0;
  letter-spacing: 0.25px;
}

.mat-input-element,
input.mat-input-element,
input[type='text'] {
  font-size: 17px;
}

.mat-form-field-outline {
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 4px 0 0 4px;
    border-right-style: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 4px 4px 0;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: white;
  border-radius: 5px;
}

.dense
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.dense
  .mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-21px) scale(0.75);
}

.mat-form-field-can-float {
  vertical-align: top;
}

.mat-mdc-progress-spinner:not(.white) circle,
mat-spinner:not(.white) circle {
  stroke: var(--primary-color) !important;
}

.mat-mdc-progress-spinner.white circle {
  stroke: white;
}

.search-input {
  .mat-form-field-prefix {
    top: 5px;
    margin-right: 8px;
  }

  .mat-form-field-label {
    color: #00000052;
    letter-spacing: 0.5px;
  }
}

.dense .mat-form-field-label-wrapper {
  top: -22px;
}

.dense .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 15px 0;
  border-top-width: 12px;
}

.button-wrap {
  display: flex;
  padding: 32px 0 0;
}

textarea {
  font-family: Lato;
  font-size: 17px;
  padding: 16px 8px 12px 8px;
  border-radius: 2.5px;
  color: rgba(0 0 0 / 87%);
  border: 1px solid rgba(0 0 0 / 25%);
}

button,
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  letter-spacing: 0.51px;
  border-radius: 2.5px;
  font-size: 17px;
  font-weight: 900;

  .mat-primary {
    color: #fff;
  }
}

button {
  .mdc-button {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.51px;
  }

  &.small {
    padding: 4px 12px;
    .mdc-button__label {
      font-size: 13px;
      line-height: 20px;
      column-gap: 4px;
    }

    .mat-icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }
  }

  &.icon-button {
    padding: 0 16px 0 12px;

    .mat-icon {
      font-size: 24px;
      width: 24px;
      height: 24px;
    }

    .icon-button-label {
      padding-left: 8px;
    }
  }
}

.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  &.mat-primary {
    background-color: var(--primary-color);
  }
}

.mat-mdc-outlined-button {
  color: var(--primary-color);
  font-size: 17px;
}

.add-button {
  color: var(--primary-color);
}

.primary-color {
  color: var(--primary-color);
}

.mat-error {
  font-size: 13px;
  margin-top: 8px;
}

.mat-form-field-required-marker {
  color: #d00;
}

/// TOOLTIP
.mat-mdc-tooltip {
  font-size: 13px !important;
  font-family: Lato, sans-serif !important;
}

/// TOOLBAR

.mat-toolbar-row,
.mat-toolbar-single-row {
  border-bottom: 1px solid lightgray;
}

.mat-toolbar {
  background: #fff;
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
}

// HEADER

.main-header {
  margin-top: 1em;
  color: var(--primary-color);
}

.active-menu {
  color: #000;
}

.router-link-active {
  color: #fff;
  font-weight: bold;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: auto;
}

// PAGE HEADER

.page-name {
  margin: 8px 32px 16px 0;
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0;
  color: var(--primary-color);
}

.page-heading {
  font-size: 37px;
  font-weight: 300;
}

.status-heading {
  font-size: 27px;
  font-weight: 300;
}

.infosubheadingdiv {
  padding-bottom: 16px;
}

// EXPANSION PANEL

:not(.mat-accordion) > .mat-expansion-panel {
  margin-bottom: 32px !important;
  border-radius: 2.5px !important;
  background-color: #fff;
}

.mat-expansion-panel-header {
  font-size: 24px;
}

.panel-infoicon {
  position: absolute;
  right: 16px;
  cursor: pointer;
  top: 0;
}

// DIALOG

.mat-mdc-dialog-title {
  font-weight: bold;
}

.btn-close {
  float: right;
}

// TOAST

.toast-item-success {
  .toast-body {
    background: #39b355;
  }
}

.toast-item-delete {
  .toast-body {
    background: #f54f4f;
  }
}

.toast-header {
  display: none;
}

// OTHER COMMON ELEMENTS

.mat-step-header {
  padding-left: 0 !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.mat-sort-header-button {
  text-align: left;
}

.arrowPopup {
  background: var(--primary-color);
}

.mat-mdc-card-title {
  padding: 16px;
}
// Left card

.leftCard {
  width: 470px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 25px;
}

.mat-mdc-paginator {
  font-size: 15px;
  color: #000000de;

  .mat-mdc-option-text {
    font-size: 15px;
  }

  .mat-mdc-select-value {
    font-size: 15px;
  }
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__leading {
    border: none;
  }
}

app-requestor-project-list {
  .mat-mdc-paginator {
    .mat-form-field-appearance-legacy .mat-form-field-infix {
      padding: 0;
    }

    .mat-mdc-paginator-page-size-label {
      margin-right: 52px;
      letter-spacing: 0.75px;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-range-label {
        margin-right: 60px;
        font-size: 17px;
      }

      .mat-mdc-paginator-navigation-previous {
        margin-right: 32px;
      }
    }
  }
}

/////// SCROLLBAR //////

* {
  font-family: Lato;
  scrollbar-width: thin;
  scrollbar-color: #462b5a #c5c5c5;
}

*::-webkit-scrollbar-track {
  background-color: #d1d1d1;
  border-radius: 6px;
}

*::-webkit-scrollbar-track:hover {
  background-color: #aaa;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #c5c5c5;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #462b5a;
  border: none;
  border-radius: 6px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

//Status styles//

.completed-status {
  background-color: #173;
  fill: #173;
}

.notstarted-status {
  background-color: #dd5d4b;
  fill: #dd5d4b;
}

.inprogress-status {
  background-color: #abab03;
  fill: #abab03;
}

.onhold-status {
  background-color: #f5b99f;
}

.cancelled-status {
  background-color: #b2b2b2;
  fill: #b2b2b2;
}

.selected-status {
  fill: #00a3e0;
}

.submitted-scenario {
  border: 2px solid #000;
}

/* media queries */

@media only screen and (max-width: 767px) {
  .app-title {
    font-size: 14px !important;
  }

  .mat-horizontal-content-container {
    overflow: hidden;
    padding: 0 0 0 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
  .mat-horizontal-content-container {
    overflow: hidden;
    padding: 0 0 0 0 !important;
  }
}

input.hidden-arrows {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.block {
  .mat-checkbox-inner-container {
    margin: 0 8px 0 0;
  }
}

app-scenario {
  .scenario-status-panel {
    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

app-scenario-deliverable {
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }
}

.mat-select-disabled {
  .mat-select-arrow {
    visibility: hidden;
  }
}

.locked {
  tr:not(.scoperow) {
    td,
    td.td-edit,
    input,
    select,
    textarea {
      background-color: white;
    }
  }

  select {
    color: black;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    opacity: 1;
  }
}

.add-pxd {
  .mat-form-field-appearance-outline {
    .mat-form-field-subscript-wrapper {
      padding: 8px 11px 0;
      letter-spacing: 0.25px;
    }
  }
}

.revision-comment-wrapper {
  .mat-form-field-hint-wrapper {
    margin-top: 8px;
  }
}

.ctr-duration-table {
  .mat-mdc-icon-button {
    line-height: 20px;
    height: 24px;
    width: 24px;
    border-radius: 0;
  }
}

.mat-form-field {
  font: 400 15px / 1.25 Lato, sans-serif;
}

.inline-mat-select {
  width: 100%;

  .mat-form-field-wrapper {
    padding: 0;

    .mat-form-field-flex {
      padding: 0;
      border-radius: 0;
      background: transparent;

      .mat-form-field-infix {
        padding: 15px;
        border: none;

        .mat-select-arrow-wrapper {
          display: block;
        }
      }
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

.mat-mdc-snack-bar-container.snackbar-warning {
  max-width: 800px;
  background: $warning;
}

.mat-mdc-snack-bar-container.snackbar-success {
  max-width: 800px;
  background: $success;
}

.mat-mdc-dialog-title {
  text-align: left;
  font: normal normal bold 21px/25px Lato, sans-serif;
  letter-spacing: 0.27px;
  color: #212121;
}

.mat-mdc-chip {
  font-size: 13px;
}

.mat-mdc-button.delete-button {
  color: #e82233;
  font-style: normal;
  font-weight: 400;
  padding: 0;
}

.wbs-code-field {
  .mat-form-field-wrapper {
    padding-bottom: 14px !important;
  }
}

// UTILITIES

.no-wrap {
  white-space: nowrap;
}
.nb-note {
  color: #d32a2e;
  padding-top: 24px;
  padding-bottom: 40px;
  font-size: 16px;
  font-weight: normal;
}
