/* TODO: remove after getting ride of all .pxdtable */

:root {
  --thin-border: 1px solid rgba(0 0 0 / 10%);
  --thick-outline: 4px solid rgba(0 0 0 / 90%);
}

.pxdcontainer {
  overflow: hidden;
  max-width: fit-content;
  width: 100%;
}

.scenario-pxd-matrix-scroll-container {
  overflow: auto;
}

.pxdtable {
  position: relative;
  background-color: #fff;
  table-layout: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  max-width: calc(var(--columns) * var(--cell-size) + 1);
  margin-bottom: 32px;

  th:first-child {
    position: relative;
    background: linear-gradient(
      33.3deg,
      #747474 50%,
      white 49%,
      white 51%,
      #747474 50%
    );
    border-right: 1px solid black;
    z-index: 2;
  }

  thead {
    position: relative;
    display: block;
  }

  th:first-child::before {
    content: 'Product';
    position: absolute;
    bottom: 3px;
    left: 8px;
    color: #fff;
  }

  tbody {
    flex: 1;
    position: relative;
    display: block;
    overflow: auto;
  }

  th:first-child::after {
    content: 'Discipline';
    position: absolute;
    top: 0;
    white-space: nowrap;
    right: 0;
    color: #fff;
  }

  th,
  td:first-child {
    color: #000;
    border-right: 1px solid black;
    overflow-wrap: anywhere;
  }

  td,
  th {
    width: 76px; //70px;
    height: 74px;
    min-width: 76px; //70px;
    font-size: 12px;
    font-weight: 400;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    padding: 2px;
    z-index: 1;
  }

  th {
    height: 50px;
    border: var(--thin-border);
    border-bottom: 1px solid #000;

    .cell-wrapper {
      padding: 0;
    }
  }

  td {
    text-align: center;
  }

  td:first-child {
    text-align: left;
    position: relative;
    z-index: 2;
    background-color: #fff;

    .inner {
      text-align: left;
      flex-direction: row;
      padding: 4px;
      justify-content: flex-start;
      overflow-wrap: anywhere;
      background-color: #fff;
    }

    .cell-wrapper {
      text-align: left;
    }
  }

  tr:last-child td {
    border-bottom: 2px solid rgba(0 0 0 / 10%);
  }

  .inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
  }

  .icon-container {
    display: flex;
  }

  .ctr-icon {
    margin: 0 2px;
    cursor: pointer;
  }

  .cell-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    padding: 4px;
    text-align: center;

    &.td-border {
      border: var(--thin-border);
    }

    &.border-column,
    &.selection-border-column {
      border-left: 1px dashed #06a5e1;
      border-right: 1px dashed #06a5e1;
    }

    &.border-row,
    &.selection-border-row {
      border-top: 1px dashed #06a5e1;
      border-bottom: 1px dashed #06a5e1;
    }

    &.border-row-first:first-child,
    &.selection-border-row-first:first-child {
      border-top: 1px dashed #06a5e1;
      border-bottom: 1px dashed #06a5e1;
      border-left: 1px dashed #06a5e1;
    }

    &.border-column-first,
    &.selection-border-column-first {
      border-left: 1px dashed #06a5e1;
      border-right: 1px dashed #06a5e1;
      border-top: 1px dashed #06a5e1;
    }

    &.hovered-cell {
      border: 1px dashed #06a5e1;
    }
  }

  .selected-block {
    border-style: dotted !important;
    border-color: #0072ab !important;
    outline: var(--thick-outline);
    outline-offset: -4px;
  }

  .td-border {
    border: var(--thin-border);
  }

  .border-column {
    border-left: 2px dashed #06a5e1;
    border-right: 2px dashed #06a5e1;
  }

  .border-row-first:first-child {
    border-top: 2px dashed #06a5e1;
    border-bottom: 2px dashed #06a5e1;
    border-left: 2px dashed #06a5e1;
  }

  .border-column-first {
    border-left: 2px dashed #06a5e1;
    border-right: 2px dashed #06a5e1;
    border-top: 2px dashed #06a5e1;
  }

  .hovered-cell {
    border: 2px dashed #06a5e1;
  }

  .selected-block {
    border-style: dotted !important;
    border-color: #0072ab !important;
    outline: var(--thick-outline);
    outline-offset: -4px;
  }
}

.td-selected {
  background-color: #00a3e0;
  color: #fff;
  position: relative;
  border: var(--thin-border);
  cursor: pointer;
}

.td-requested {
  color: #00a3e0;
  border: var(--thin-border);
}

.td-received {
  background-color: #82c590;
  color: #fff;
  border: var(--thin-border);
}

.td-not-started {
  background-color: #dd5c4b;
  color: #fff;
  position: relative;
  border: var(--thin-border);
}

.td-border {
  border: var(--thin-border);
}

.td-completed {
  background-color: #173;
  color: #fff;
  position: relative;
  border: var(--thin-border);
}

.td-packageownerview {
  content: 'package';
  background-color: #e3e9ed;
  color: #999;
  position: relative;
  border: var(--thin-border);
}

.td-cancelled {
  background-color: #c1c1c1;
  color: white;
  position: relative;
  border: var(--thin-border);
}

.td-inprogress {
  background-color: #abab03;
  color: white;
  position: relative;
  border: var(--thin-border);
}

.td-canselect {
  background-color: #0072ab12;
  color: white;
  position: relative;
  border: var(--thin-border);
  cursor: pointer;
}
