@import 'variables';

// how much vertical space content around datagrid needs

.datagrid-scroll-wrapper {
  --datagrid-vertical-padding: 240px;

  overflow-x: auto;
  max-height: calc(100vh - var(--datagrid-vertical-padding));
}

.datagrid {
  --cell-padding: 8px;
  --datagrid-row-height: 24px;

  border-collapse: separate;
  border-spacing: 0;
  font-size: 15px;
  background-color: #fff;
  position: relative;

  th,
  td {
    position: relative;
    line-height: 24px;
  }

  th,
  td,
  input {
    box-sizing: border-box;
    height: calc(var(--datagrid-row-height) + 2 * var(--cell-padding));
    background-color: #fff;
  }

  .editable {
    padding: 0;
    background-color: #f2f2f2;

    input {
      width: 100%;
      padding: calc(var(--cell-padding));
      border: 0;
      background-color: #f2f2f2;
      font-size: inherit;
    }
  }

  td,
  th {
    &.align-center {
      text-align: center !important;
    }
  }

  input {
    outline-offset: -2px;
    outline-color: var(--highlight-color);

    &:focus,
    &:focus-visible,
    &:-moz-focusring {
      outline: 2px solid var(--highlight-color);
    }

    &.ng-invalid {
      box-shadow: 0 0 4px $error inset;
      background-color: transparentize($error, 0.9) !important;
      caret-color: $error;
      outline-color: $error;

      &:focus,
      &:focus-visible,
      &:-moz-focusring {
        outline-color: $error;
      }
    }
  }

  thead {
    position: sticky;
    z-index: 3;
    top: 0;

    tr {
      position: relative;
    }
  }

  tbody {
    position: relative;
    z-index: 2;

    tr {
      position: relative;
    }
  }

  thead,
  tbody .block,
  tbody .scope,
  tfoot {
    td,
    th {
      &.sticky-right-edge {
        border-right: 1px solid #888;
      }
    }
  }
}
