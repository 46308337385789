.info-table {
  --cell-padding: 8px;

  width: 100%;
  background-color: #fff;

  tr.mat-mdc-row,
  tr.mat-mdc-footer-row {
    height: auto;
  }

  tr:nth-child(even) {
    background-color: #fafafa;
  }

  th {
    color: #888;
    font-weight: normal;
    width: 200px;
    padding: 8px;
    text-transform: capitalize;
    vertical-align: top;
  }

  td {
    padding: 8px;
    border-bottom: none;
    white-space: pre-line;

    &.mat-mdc-cell {
      border-bottom: none;
    }
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type,
  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding: 8px;
  }

  caption {
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    padding: 16px 8px;
    background-color: #fff;
  }
}
