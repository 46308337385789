.mat-mdc-card-title {
  padding: 16px 0;
}

.mat-drawer-content {
  width: 100%;
  padding-bottom: 0;
  overflow: hidden !important;
  overflow-y: auto !important;
  background-color: #f7f7f7;

  .body {
    padding: 32px 32px 40px;
  }
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #00000028;
}
