.mat-mdc-dialog-container {
  --modal-vertical-padding: 140px;

  .mat-mdc-dialog-surface {
    max-height: calc(95vh - var(--modal-vertical-padding));
    padding: 0 24px 8px;
  }
}

.mat-mdc-dialog-actions {
  justify-content: flex-end;
  column-gap: 16px;
}

.dialog-title {
  margin: 0 0 20px;
  font-size: 21px;
  font-weight: bold;
}

.dialog-buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
